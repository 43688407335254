<template>
  <div class="p-group-create">
    <CommonHeader>
      {{ $t('group.create.name') }}
      <span
        slot="left"
        class="m-send-btn"
        @click="goBack"
      >
        {{ $t('cancel') }}
      </span>
      <template slot="right">
        <CircleLoading v-if="loading" />
        <a
          v-else
          :class="{ disabled }"
          class="m-send-btn"
          @click.prevent="handleOk"
        >
          {{ $t('create') }}
        </a>
      </template>
    </CommonHeader>

    <form>
      <FormAvatarItem
        v-model="avatar"
        :label="$t('group.create.label.avatar')"
        shape="square"
        type="blob"
      />

      <FormInputItem
        v-model="form.name"
        :label="$t('group.create.label.name')"
        maxlength="20"
        :placeholder="$t('group.create.placeholder.name', [20])"
      />

      <FormSelectItem
        v-model="category.name"
        :label="$t('group.create.label.category')"
        :placeholder="$t('group.create.placeholder.category')"
        @click="switchCate"
      />

      <!-- 标签选择 -->
      <FormTagsItem
        v-model="tags"
        :label="$t('group.create.label.tag')"
        :placeholder="$t('group.create.placeholder.tag')"
      />

      <!-- 位置选择 -->
      <FormLocationItem v-model="location" :label="$t('group.create.label.location')" />

      <FormInputItem
        v-model="form.summary"
        type="textarea"
        :label="$t('group.create.label.bio')"
        maxlength="255"
        warnlength="200"
        :placeholder="$t('group.create.placeholder.bio')"
      />

      <hr>

      <FormSwitchItem v-model="allowFeed" :label="$t('group.create.label.sync')" />

      <FormSwitchItem v-model="privateMode" :label="$t('group.create.label.private')" />

      <template v-if="privateMode">
        <section class="c-form-item bb">
          <label class="check-label" @click="needPaid = true">
            <input :value="needPaid" type="checkbox">
            <span class="checkmark" />
            <span>{{ $t('group.create.paid') }}</span>
          </label>
          <label class="label-amount">
            <input
              v-model="form.money"
              type="text"
              :placeholder="$t('group.create.placeholder.paid')"
              maxlength="8"
            >
            <span>{{ $t('currency.unit') }}</span>
          </label>
        </section>

        <section class="c-form-item bb">
          <label class="check-label" @click="needPaid = false">
            <input :value="!needPaid" type="checkbox">
            <span class="checkmark" />
            <span>{{ $t('group.create.free') }}</span>
          </label>
        </section>
      </template>

      <p class="footage">{{ $t('group.create.need_review') }}</p>

      <FormInputItem
        v-model="form.notice"
        type="textarea"
        :label="$t('group.create.label.notice')"
        :placeholder="$t('group.create.placeholder.optional')"
        maxlength="2000"
        warnlength="200"
      />

      <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
      <i18n
        tag="p"
        path="group.create.agree"
        class="footage"
      >
        <a
          slot="protocol"
          href="javascript:;"
          @click="showProtocol"
        >
          {{ $t('group.create.protocol', {protocol: siteName}) }}
        </a>
      </i18n>
    </form>

    <!-- 选择圈子分类遮罩层 -->
    <ChooseGroupCate ref="chooseGroupCate" @change="onGroupCateChange" />

    <!-- 圈子协议遮罩层 -->
    <GroupProtocol ref="protocol" />
  </div>
</template>

<script>
  import ChooseGroupCate from './components/chooseGroupCate.vue'
  import GroupProtocol from './components/GroupProtocol.vue'
  import { encodeGeoHash } from '@/util/geohash'
  import { mapState } from 'vuex'

  export default {
    name: 'GroupCreate',
    components: {
      ChooseGroupCate,
      GroupProtocol,
    },
    data () {
      return {
        siteName: process.env.VUE_APP_NAME,
        loading: false,
        needPaid: false,
        category: {}, // 分类
        tags: [], // 标签
        location: {
          label: this.$t('group.create.placeholder.location'),
        },
        avatar: null, // 头像, Blob 或 File 对象

        privateMode: false, // 是否私有圈子

        form: {
          name: '', // 圈名
          summary: '', // 简介
          allow_feed: 0,
          mode: 'public',
          money: '',
          notice: '', // 公告
        },
      }
    },
    computed: {
      disabled () {
        // 必填字段
        if (
          !this.avatar ||
          !this.form.name ||
          !this.tags.length ||
          !this.form.mode
        ) { return true }

        if (this.form.money === null) {
          this.form.money = ''
        }

        // 如果设置了收费圈子却没有设置金额 或金额超出范围 不允许提交
        if (this.form.mode === 'paid' && !this.form.money.match(/^\d{1,8}$/)) { return true }

        return false
      },
      allowFeed: {
        get () {
          return !!this.form.allow_feed
        },
        set (val) {
          this.form.allow_feed = val ? 1 : 0
        },
      },
      ...mapState({
        CONFIG: 'CONFIG',
        user: 'CURRENTUSER',
      }),
    },
    watch: {
      privateMode (val) {
        this.form.mode = !val ? 'public' : this.needPaid ? 'paid' : 'private'
      },
      needPaid (val) {
        if (val) this.form.mode = 'paid'
        else {
          this.form.mode = 'private'
          this.form.money = null
        }
      },
      'form.money' (val) {
        if (val) this.needPaid = true
      },
    },
    created () {
      const { CONFIG: { 'group:create': { need_verified: needVerified } }, user: { verified } } = this
      if (needVerified && !verified) {
        this.$Message.error('需要认证后才能创建圈子')
        this.goBack()
      }
    },
    methods: {
      showProtocol () {
        this.$refs.protocol.show()
      },
      switchCate () {
        this.$refs.chooseGroupCate.show()
      },
      onGroupCateChange (cate) {
        this.category = cate
      },
      async handleOk () {
        if (this.loading || this.disabled) return
        this.loading = true

        // 构造 FormData 对象 (因为头像上传需要)
        const formData = new FormData()

        // 挂载表单数据
        for (const key in this.form) {
          if (!this.form[key]) continue
          formData.append(key, this.form[key])
        }

        // 挂载标签
        for (const tag of this.tags) {
          formData.append('tags[][id]', tag.id)
        }

        // 挂载位置信息
        if (this.location.label) {
          const geoHash = encodeGeoHash(this.location.lat, this.location.lng)
          formData.append('location', this.location.label)
          formData.append('latitude', this.location.lat)
          formData.append('longitude', this.location.lng)
          formData.append('geo_hash', geoHash)
        }

        // 挂载头像
        formData.append('avatar', this.avatar)

        // 提交后端
        const payload = { category: this.category.id, formData }
        const result = await this.$store.dispatch('group/createGroup', payload)
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.loading = false
          })
        this.$Message.success(result)
        this.goBack()
      },
    },
  }
</script>

<style lang="less" scoped>
@import url("~@/components/form/formItem.less");

.p-group-create {
  .c-form-item {
    background-color: #fff;

    > label {
      flex: auto;
    }

    /deep/ .input-wrap {
      color: #333;

      > input {
        color: #333;
      }
    }

    /deep/ .c-textarea-input {
      .textarea {
        color: #333;
      }
    }
  }

  .c-form-switch-item {
    border-bottom: 1px solid @border-color; /* no */
  }

  .footage {
    color: #b3b3b3;
    font-size: 20px;
    margin: 10px 14px 30px;
  }

  .check-label {
    display: block;
    position: relative;
    padding-left: 48px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    color: #333;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &[value="true"] ~ .checkmark::after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 8px;
      left: 0;
      height: 30px;
      width: 30px;
      border: 1px solid @border-color; /* no */
      vertical-align: middle;

      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 8px;
        top: 0;
        width: 12px;
        height: 24px;
        border: solid #70d0ab;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }

  .label-amount {
    display: flex;
    justify-content: space-around;
    text-align: right;
    padding-right: 20px;
    width: 10em;

    input {
      display: inline-block;
      height: 100%;
      width: 100%;
      text-align: right;
      margin-right: 20px;
      font-size: 30px;
    }

    > span {
      flex: none;
    }
  }
}

.bb {
  border-bottom: 1px solid @border-color; /* no */
}
</style>

<template>
  <Transition>
    <RouterView />
  </Transition>
</template>

<script>
  export default {
    name: 'GroupDetailBase',
  }
</script>

<template>
  <ArticleCard
    ref="article"
    type="post"
    :article="postId"
    :liked="liked"
    :loading="loading"
    class="p-group-post-detail"
    @like="likeFeed"
    @comment="$refs.comments.open()"
    @more="moreAction"
  >
    <CommonHeader slot="head">
      <Avatar :user="user" size="tiny" />
      <span class="m-text-cut m-flex-none username">
        {{ user.name }}
      </span>
      <template
        v-if="!isMine"
        slot="right"
        :class="{ primary: relation.status !== 'unFollow' }"
      >
        <svg class="m-style-svg m-svg-def" @click="followUserByStatus(relation.status)">
          <use :xlink:href="relation.icon" />
        </svg>
      </template>
    </CommonHeader>

    <!-- 内容 -->
    <JoLoadMore
      slot="main"
      ref="loadmore"
      :show-bottom="false"
      @onRefresh="onRefresh"
    >
      <main class="m-art content-body">
        <div class="m-art-body">
          <h2 v-if="title" class="post-title">{{ title }}</h2>
          <p class="description">来自 <span>{{ group.name }}</span></p>
          <video
            v-if="!!video"
            :poster="cover_file"
            class="feed-detail-video"
            controls
            autoplay
          >
            <source :src="video_file" type="video/mp4">
          </video>
          <AsyncFile
            v-for="img in images"
            v-if="img.file"
            :key="img.file"
            :file="img.file"
          >
            <img
              v-if="props.src"
              slot-scope="props"
              :src="props.src"
              @click="onFileClick(img)"
            >
          </AsyncFile>
          <p class="m-text-box markdown-body" v-html="formatBody(feedContent)" />
        </div>

        <!-- 点赞组件 -->
        <ArticleLike
          :likers="likes"
          :like-count="likeCount"
          :time="time"
          :view-count="feed.views_count || 0"
        />

        <!-- 打赏组件 -->
        <ArticleReward
          v-if="allowReward"
          type="post"
          :article="postId"
          :is-mine="isMine"
          v-bind="reward"
          @success="fetchPostRewards"
        />
      </main>

      <!-- 详情页广告位 -->
      <DetailAd type="group:post" />

      <!-- 评论列表 -->
      <ArticleComments
        ref="comments"
        type="post"
        :article="postId"
        :total.sync="commentCount"
        :fetching="fetchComing"
        @reply="replyComment"
      />
    </JoLoadMore>
  </ArticleCard>
</template>

<script>
  import markdownIt from 'markdown-it'
  import plusImagePlugin from 'markdown-it-plus-image'
  import FeedDetail from '@/page/feed/FeedDetail.vue'
  import DetailAd from '@/components/advertisement/DetailAd.vue'
  import wechatShare from '@/util/wechatShare.js'
  import { followUserByStatus } from '@/api/user.js'
  import { markdownText } from '@/filters.js'
  import * as api from '@/api/group.js'

  export default {
    name: 'GroupPostDetail',
    components: {
      DetailAd,
    },
    mixins: [FeedDetail],
    data: () => ({
      feed: {
        likes: [],
      },
    }),
    computed: {
      post () {
        return this.feed
      },
      allowReward () {
        const config = this.$store.state.CONFIG || {}
        return (config['group-reward'] || {}).status || false
      },
      video_file () {
        return false
      },
      cover_file () {
        return false
      },
      postId () {
        return Number(this.$route.params.postId)
      },
      groupId () {
        return Number(this.$route.params.groupId)
      },
      group () {
        return this.feed.group || {}
      },
      likes () {
        return this.feed.likes || []
      },
      liked: {
        get () {
          return !!this.feed.liked
        },
        set (val) {
          this.feed.liked = val
        },
      },
      likeCount: {
        get () {
          return this.feed.likes_count || 0
        },
        set (val) {
          this.feed.likes_count = ~~val
        },
      },
      commentCount: {
        get () {
          return this.feed.comments_count || 0
        },
        set (val) {
          this.feed.comments_count += val
        },
      },
      firstImage () {
        const images = this.images
        if (!images.length) {
          return ''
        }
        const file = images[0] || {}
        return this.$http.defaults.baseURL + '/files/' + file.id + '?w=300&h=300'
      },
      feedContent () {
        const { body = '' } = this.feed
        return body
      },
      has_collect: {
        get () {
          return this.feed.collected
        },
        set (val) {
          this.feed.collected = val
        },
      },
      isGroupManager () {
        const { role = '' } = this.group.joined || {}
        return ['founder', 'administrator'].includes(role)
      },
      relation: {
        get () {
          const relations = {
            unFollow: {
              text: '关注',
              status: 'unFollow',
              icon: '#icon-unFollow',
            },
            follow: {
              text: '已关注',
              status: 'follow',
              icon: '#icon-follow',
            },
            eachFollow: {
              text: '互相关注',
              status: 'eachFollow',
              icon: '#icon-eachFollow',
            },
          }
          const { follower, following } = this.user
          const relation = follower && following
            ? 'eachFollow'
            : follower
              ? 'follow'
              : 'unFollow'
          return relations[relation]
        },

        set (val) {
          this.user.follower = val
        },
      },
    },
    beforeMount () {
      if (this.isIosWechat) {
        this.reload(this.$router)
      }
    },
    mounted () {
      this.fetchFeed()
    },
    methods: {
      onRefresh () {
        this.$refs.loadmore.beforeRefresh()
        this.fetchFeed()
      },
      /**
       * 收藏
       * @Author   Wayne
       * @DateTime 2018-04-26
       * @Email    qiaobin@zhiyicx.com
       * @return   {[type]}            [description]
       */
      handleCollection () {
        api.collectGroupPost(this.feed.id, this.has_collect).then(() => {
          this.$Message.success('操作成功')
          this.has_collect = !this.has_collect
        })
      },
      formatBody (body) {
        return markdownIt({
          html: true,
        })
          .use(plusImagePlugin, `${this.$http.defaults.baseURL}/files/`)
          .render(body)
      },
      fetchFeed () {
        if (this.fetching) return
        this.fetching = true

        const shareUrl =
          window.location.origin +
          process.env.BASE_URL.substr(0, process.env.BASE_URL.length - 1) +
          this.$route.fullPath
        const signUrl =
          this.$store.state.BROWSER.OS === 'IOS' ? window.initUrl : shareUrl

        api.getGroupPost(this.groupId, this.postId)
          .then(({ data }) => {
            this.fetching = false
            this.loading = false
            this.$refs.loadmore.afterRefresh()
            this.feed = { ...data }
            this.user = this.feed.user
            this.oldId = this.postId
            this.fetchPostComments()
            this.fetchPostRewards()
            this.fetchLikes()

            this.isWechat &&
              wechatShare(signUrl, {
                title: data.title,
                desc: markdownText(data.body),
                link: shareUrl,
                imgUrl:
                  data.images.length > 0
                    ? `${this.$http.defaults.baseURL}/files/${
                      data.images[0].file
                    }`
                    : '',
              })
          })
      },
      fetchPostRewards (inc = 0) {
        if (inc) {
          this.rewardCount += 1
          this.rewardAmount += inc
        } else {
          const { reward_number: count = 0, reward_amount: amount = 0 } = this.post
          this.rewardCount = Number(count)
          this.rewardAmount = Number(amount)
        }
        api.getPostRewards(this.postId, { limit: 10 })
          .then(({ data = [] }) => {
            this.rewardList = data
          })
      },
      fetchPostComments () {
        this.$refs.comments.fetch()
      },
      fetchLikes () {
        this.$http
          .get(
            `/plus-group/group-posts/${this.postId}/likes`,
            {
              params: { limit: 8 },
            },
            {
              validateStatus: s => s === 200,
            },
          )
          .then(({ data = [] }) => {
            // this.feed.likes = data;
            this.feed = { ...this.feed, ...{ likes: data } }
          })
      },
      likeFeed () {
        api
          .likeGroupPost(this.postId, this.liked)
          .then(() => {
            if (this.liked) {
              this.liked = false
              this.likeCount -= 1
              this.feed.likes = this.feed.likes.filter(like => {
                return like.user_id !== this.CURRENTUSER.id
              })
            } else {
              this.liked = true
              this.likeCount += 1
              if (this.feed.likes.length < 5) {
                this.feed.likes.push({
                  user: this.CURRENTUSER,
                  id: new Date().getTime(),
                  user_id: this.CURRENTUSER.id,
                })
              }
            }
          })
          .finally(() => {
            this.fetching = false
          })
      },
      moreAction () {
        const actions = []
        if (this.has_collect) {
          actions.push({
            text: '取消收藏',
            method: () => {
              api.uncollectPost(this.postId).then(() => {
                this.$Message.success('取消收藏')
                this.has_collect = false
              })
            },
          })
        } else {
          actions.push({
            text: '收藏',
            method: () => {
              api.collectionPost(this.postId).then(() => {
                this.$Message.success('已加入我的收藏')
                this.has_collect = true
              })
            },
          })
        }

        if (this.isGroupManager) {
          if (!this.feed.pinned) {
            actions.push({
              text: '置顶帖子',
              method: () => {
                this.$bus.$emit('applyTop', {
                  type: 'post-manager',
                  api: api.pinnedPost,
                  payload: this.feed.id,
                  callback: () => {
                    this.$Message.success('置顶成功！')
                    this.fetchFeed()
                  },
                })
              },
            })
          } else {
            actions.push({
              text: '撤销置顶',
              method: () => {
                const actions = [
                  {
                    text: '撤销置顶',
                    method: () => {
                      this.$store
                        .dispatch('group/unpinnedPost', {
                          postId: this.feed.id,
                        })
                        .then(() => {
                          this.$Message.success('撤销置顶成功！')
                          this.fetchFeed()
                        })
                    },
                  },
                ]
                setTimeout(() => {
                  this.$bus.$emit(
                    'actionSheet',
                    actions,
                    '取消',
                    '确认撤销置顶?',
                  )
                }, 200)
              },
            })
          }
        } else if (this.isMine && !this.feed.pinned) {
          actions.push({
            text: '申请帖子置顶',
            method: () => {
              this.$bus.$emit('applyTop', {
                type: 'post',
                api: api.applyTopPost,
                payload: this.postId,
              })
            },
          })
        }
        if (this.isMine || this.isGroupManager) {
          actions.push({
            text: '删除帖子',
            method: () => {
              setTimeout(() => {
                const actions = [
                  {
                    text: '删除',
                    style: { color: '#f4504d' },
                    method: () => {
                      this.$store
                        .dispatch('group/deletePost', {
                          groupId: this.groupId,
                          postId: this.postId,
                        })
                        .then(() => {
                          this.$Message.success('删除帖子成功')
                          this.goBack()
                        })
                    },
                  },
                ]
                this.$bus.$emit('actionSheet', actions, '取消', '确认删除?')
              }, 200)
            },
          })
        }
        if (!this.isMine) {
          actions.push({
            text: '举报',
            method: () => {
              this.$bus.$emit('report', {
                type: 'post',
                payload: this.feed.id,
                username: this.user.name,
                reference: this.title,
              })
            },
          })
        }
        this.$bus.$emit('actionSheet', actions, '取消')
      },
      // TODO: refactor 'followUserByStatus' api to vuex.action
      followUserByStatus (status) {
        if (!status || this.fetchFollow) return
        this.fetchFollow = true

        followUserByStatus({
          id: this.user.id,
          status,
        }).then(follower => {
          this.relation = follower
          this.fetchFollow = false
        })
      },
      replyComment (comment) {
        const actions = []
        // 是否是自己的评论
        if (comment.user_id === this.CURRENTUSER.id) {
          // 是否是自己文章的评论
          const isOwner = comment.user_id === this.user.id
          actions.push({
            text: isOwner ? '评论置顶' : '申请评论置顶',
            method: () => {
              this.$bus.$emit('applyTop', {
                isOwner,
                type: 'postComment',
                api: api.applyTopPostComment,
                payload: { postId: Number(this.postId), commentId: comment.id },
                callback: this.fetchPostComments,
              })
            },
          })
          actions.push({
            text: '删除评论',
            method: () => this.$refs.comments.delete(comment.id),
          })
        } else {
          actions.push({
            text: '回复',
            method: () => this.$refs.comments.open(comment.user),
          })
          actions.push({
            text: '举报',
            method: () => {
              this.$bus.$emit('report', {
                type: 'postComment',
                payload: comment.id,
                username: comment.user.name,
                reference: comment.body,
              })
            },
          })
        }
        this.$bus.$emit('actionSheet', actions)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-group-post-detail {
  > main {
    display: flex;
  }
  .content-body {
    flex: auto;
    background-color: #fff;
  }
}
.post-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.description {
  margin-top: 20px;
  color: #ccc;
  font-size: 28px;

  > span {
    color: @primary;
  }
}
</style>

<template>
  <div class="p-search-post">
    <SearchBar v-model="keyword" position="fixed" />

    <JoLoadMore
      ref="loadmore"
      :auto-load="false"
      :show-bottom="list.length > 0"
      class="lodemore"
      @onRefresh="onRefresh"
      @onLoadMore="onLoadMore"
    >
      <GroupFeedCard
        v-for="post in list"
        :key="post.id"
        :feed="post"
        :group="group"
      />
    </JoLoadMore>

    <div v-show="noResult && !list.length" class="m-no-find" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import * as api from '@/api/group'
  import SearchBar from '@/components/common/SearchBar.vue'
  import GroupFeedCard from '@/components/FeedCard/GroupFeedCard.vue'

  export default {
    name: 'SearchPost',
    components: {
      SearchBar,
      GroupFeedCard,
    },
    data () {
      return {
        keyword: '',
        list: [],
        noResult: false,
      }
    },
    computed: {
      ...mapState('group', {
        group: 'current',
      }),
      groupId () {
        return this.$route.params.groupId
      },
    },
    watch: {
      keyword (val, oldVal) {
        if (val.trim() === '') return (this.list = [])
        if (val.trim() === oldVal.trim()) return
        this.$refs.loadmore.beforeRefresh()
      },
    },
    methods: {
      async onRefresh () {
        if (!this.keyword) return
        this.noResult = false
        const params = {
          group_id: this.groupId,
          keyword: this.keyword,
        }
        const { data: list } = await api.searchPosts(params)
        this.list = list
        this.noResult = !this.list.length
        this.$refs.loadmore.afterRefresh(list.length < 15)
      },

      async onLoadMore () {
        if (!this.keyword) return
        const params = {
          group_id: this.groupId,
          keyword: this.keyword,
          offset: this.list.length,
        }
        const { data: list = [] } = await api.searchPosts(params)
        this.list.push(...list)
        this.$refs.loadmore.afterLoadMore(list.length < 15)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-search-post {
  height: 100%;

  /deep/ .c-search-bar {
    top: 0;
  }
  /deep/.jo-loadmore-main {
    margin-top: 0.9rem;
  }

  .m-no-find {
    height: calc(~"100% - 90px");
  }
}
</style>

<template>
  <div class="p-group-info">
    <CommonHeader>{{ $t('group.information') }}</CommonHeader>

    <form>
      <FormAvatarItem
        v-model="group.avatar"
        :readonly="true"
        :label="$t('group.avatar')"
        shape="square"
        type="storage"
      />

      <FormInputItem
        v-model="group.name"
        :readonly="true"
        :label="$t('group.create.label.name')"
      />

      <FormInputItem
        v-model="category.name"
        :readonly="true"
        :label="$t('group.create.label.category')"
      />

      <!-- 标签选择 -->
      <FormTagsItem
        v-model="group.tags"
        :label="$t('group.create.label.tag')"
        :readonly="true"
      />

      <!-- 位置选择 -->
      <FormInputItem
        :value="location"
        :readonly="true"
        :label="$t('group.create.label.location')"
      />

      <FormInputItem
        :value="group.summary"
        :readonly="true"
        type="textarea"
        :label="$t('group.create.label.bio')"
      />

      <hr>

      <FormInputItem
        :value="group.notice"
        :readonly="true"
        type="textarea"
        :label="$t('group.create.label.notice')"
      />
    </form>
  </div>
</template>

<script>
  export default {
    name: 'GroupInfo',
    computed: {
      group () {
        return this.$store.state.group.current || {}
      },
      groupId () {
        return this.$route.params.groupId
      },
      category () {
        return this.group.category || {}
      },
      location () {
        const location = this.group.location
        if (location) return location
        return this.$t('group.create.no_location')
      },
    },
    created () {
      if (!this.group.id) this.fetchGroupInfo()
    },
    methods: {
      fetchGroupInfo () {
        this.$store.dispatch('group/getGroupById', { groupId: this.groupId })
      },
    },
  }
</script>

<style lang="less" scoped>
@import url("~@/components/form/formItem.less");

.p-group-info {
  .c-form-item {
    background-color: #fff;

    > label {
      flex: auto;
    }

    /deep/ .input-wrap {
      color: #333;

      > input {
        color: #333;
      }
    }

    /deep/ .c-textarea-input {
      .textarea {
        color: #333;
      }
    }
  }

  .c-form-switch-item {
    border-bottom: 1px solid @border-color; /* no */
  }

  .footage {
    color: #b3b3b3;
    font-size: 20px;
    margin: 10px 14px 30px;
  }

  .check-label {
    display: block;
    position: relative;
    padding-left: 48px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    color: #333;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &[value="true"] ~ .checkmark::after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 8px;
      left: 0;
      height: 30px;
      width: 30px;
      border: 1px solid @border-color; /* no */
      vertical-align: middle;

      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 8px;
        top: 0;
        width: 12px;
        height: 24px;
        border: solid #70d0ab;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }

  .label-amount {
    display: flex;
    justify-content: space-around;
    text-align: right;
    padding-right: 20px;
    width: 10em;

    input {
      display: inline-block;
      height: 100%;
      width: 100%;
      text-align: right;
      margin-right: 20px;
      font-size: 30px;
    }

    > span {
      flex: none;
    }
  }
}

.bb {
  border-bottom: 1px solid @border-color; /* no */
}
</style>

<template>
  <div class="p-group-black-list">
    <JoLoadMore
      ref="loadmore"
      :show-bottom="hasData && !keyword"
      :auto-load="false"
      @onRefresh="onRefresh"
      @onLoadMore="onLoadMore"
    >
      <SearchBar v-model="keyword" class="search-bar" />

      <div style="padding-top: 55px;">
        <template v-if="keyword.length">
          <ul>
            <li v-for="m in searchList" :key="m.id">
              <GroupUserItem :member="m" :joined="group.joined" />
            </li>
          </ul>
        </template>

        <template v-else>
          <h3>黑名单({{ blackList.length > 0 ? blackList.length : 0 }})</h3>
          <ul>
            <li v-for="m in blackList" :key="m.id">
              <GroupUserItem
                :member="m"
                :joined="group.joined"
                @more="onMoreClick(m.id)"
              />
            </li>
          </ul>
        </template>
      </div>
    </JoLoadMore>
  </div>
</template>

<script>
  import SearchBar from '@/components/common/SearchBar.vue'
  import GroupUserItem from '../components/GroupUserItem.vue'

  export default {
    name: 'GroupBlackList',
    components: { SearchBar, GroupUserItem },
    data () {
      return {
        keyword: '',
        blackList: [],
        searchList: [],
      }
    },
    computed: {
      group() {
        return this.$store.state.group.current
      },
      groupId() {
        return Number(this.$route.params.groupId)
      },
      hasData() {
        return Boolean(this.blackList.length)
      },
    },
    watch: {
      keyword(val, oldVal) {
        if (val.trim() === '') return (this.searchList = [])
        if (val.trim() === oldVal.trim()) return
        this.searchUser(val)
      },
    },
    mounted () {
      if (!this.group.id) this.fetchGroup()
      // this.fetchMembers()
      this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      async onRefresh () {
        await this.$store.dispatch('group/getMembers', {
          groupId: this.groupId,
          type: 'blacklist',
          limit: 100,
        }).then((data) => {
          this.blackList = [...data]
          this.$refs.loadmore.afterRefresh(data.length < 100)
        }) 

        // const noMore = this.blackList.length >= 100
        // console.log(noMore)
        // this.$refs.loadmore.afterRefresh(noMore)
      },
      async onLoadMore () {
        const lastMember = [...this.blackList].pop() || {}
        await this.$store.dispatch('group/getMembers', {
          groupId: this.groupId,
          after: lastMember.id,
          type: 'blacklist',
        }).then((data) => {
          this.blackList = [...this.blackList, ...data]
          this.$refs.loadmore.afterLoadMore(data.length < 100)
        }) 
      },
      async fetchMembers () {
        this.blackList = await this.$store.dispatch('group/getMembers', {
          groupId: this.groupId,
          type: 'blacklist',
          limit: 100,
        })
      },
      fetchGroup () {
        this.$store.dispatch('group/getGroupById', {groupId: this.groupId})
      },
      async searchUser(keyword) {
        this.searchList = []
        const result = await this.$store.dispatch('group/getMembers', {
          groupId: this.groupId,
          name: keyword,
          type: 'blacklist',
        })
        this.searchList = result
      },
      onMoreClick(memberId) {
        const actions = []
        actions.push({
          text: '移出黑名单',
          method: async () => {
            await this.$store.dispatch('group/moveoutBlackList', {
              groupId: this.groupId,
              memberId,
            })
            this.blackList = this.blackList.filter(m => m.id !== memberId)
            this.$Message.success('操作成功')
            this.fetchMembers()
          },
        })
        this.$bus.$emit('actionSheet', actions)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-group-black-list {
  h3 {
    padding: 15px 30px;
    font-size: 28px;
  }

  > ul {
    background-color: #fff;
  }
}
</style>

<template>
  <div class="p-user-groups">
    <CommonHeader>{{ title }}</CommonHeader>

    <main>
      <JoLoadMore
        ref="loadmore"
        :auto-load="false"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <ul>
          <li
            v-for="group in groups"
            :key="group.id"
            class="m-bb1"
          >
            <GroupItem :group="group" />
          </li>
        </ul>
      </JoLoadMore>
    </main>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import GroupItem from './components/GroupItem.vue'
  import { getGroupsByUser } from '@/api/group.js'

  export default {
    name: 'UserGroups',
    components: {
      GroupItem,
    },
    data () {
      const GROUPS = new Map()
      return {
        preUID: 0,
        GROUPS,
        groupsChangeTracker: 1,
      }
    },
    computed: {
      ...mapState(['CURRENTUSER']),
      userId () {
        return ~~this.$route.params.userId
      },
      title () {
        if (this.CURRENTUSER.id === this.userId) return this.$t('group.my')
        return this.$t('group.ta')
      },
      groups () {
        return this.groupsChangeTracker && Array.from(this.GROUPS.values())
      },
    },
    created () {
      document.title = this.title
    },
    mounted () {
      if (this.preUID !== this.userId) {
        this.GROUPS.clear()
        this.groupsChangeTracker = 1
        this.preUID = this.userId
      }
      this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      formateGroups (groups) {
        groups.forEach(group => {
          this.GROUPS.set(group.id, group)
          this.groupsChangeTracker += 1
        })
      },
      onRefresh () {
        getGroupsByUser(this.userId, 15).then(({ data = [] }) => {
          this.formateGroups(data)
          this.$refs.loadmore.afterRefresh(data.length < 15)
        })
      },
      onLoadMore () {
        getGroupsByUser(this.userId, 15, this.groups.length).then(
          ({ data = [] }) => {
            this.formateGroups(data)
            this.$refs.loadmore.afterLoadMore(data.length < 15)
          },
        )
      },
    },
  }
</script>

<template>
  <div class="p-group-post-create">
    <CommonHeader>
      发帖
      <span
        slot="right"
        :class="{ disabled }"
        class="primary"
        @click="onSubmit"
      >
        发布
      </span>
    </CommonHeader>

    <section v-if="!groupId" class="form-select-item" @click="selectGroup">
      <label>选择圈子</label>
      <div class="input-wrap">
        <span class="placeholder">{{ group.name }}</span>
        <svg class="m-style-svg m-svg-def m-entry-append">
          <use xlink:href="#icon-arrow-right" />
        </svg>
      </div>
    </section>

    <main>
      <div class="title-wrap">
        <input
          v-model="title"
          type="text"
          placeholder="输入标题，20字以内（必填）"
          maxlength="20"
        >
      </div>

      <div class="content-wrap">
        <div class="textarea-shadow">{{ content }}</div> <!-- 用于撑起文本框自适应高度 -->
        <textarea
          v-model="content"
          placeholder="输入要说的话（必填）"
          rows="1"
        />
      </div>
      <ImageList :edit="false" style="padding: .3rem 0 0" />
    </main>

    <FormSwitchItem
      v-if="group.allow_feed"
      v-model="feed"
      class="form-switch-item"
      label="同步至动态"
    />

    <ChooseGroup
      v-if="!groupId"
      ref="chooseGroup"
      @change="onGroupChange"
    />
  </div>
</template>

<script>
  import ChooseGroup from './components/ChooseGroup.vue'
  import ImageList from '@/page/post/components/ImageList'
  import { mapGetters } from 'vuex'
  import * as api from '@/api/group'
  import { draftDelete } from '@/api/user'

  export default {
    name: 'GroupPostCreate',
    components: {
      ChooseGroup,
      ImageList,
    },
    data () {
      return {
        title: '',
        content: '',
        group: {},
        feed: false,
        loading: false,
        isneedLeave: true,
      }
    },
    computed: {
      groupId () {
        return this.$route.query.group
      },
      draftId () {
        return ~~this.$route.query.draftId
      },
      disabled () {
        if (!this.groupId && !this.group.id) return true
        return !this.title || !this.content
      },
      ...mapGetters(['composePhoto']),
    },
    created () {
      if (this.draftId > 0) {
        this.fetchDraft()
      }
      if (this.$route.query.group) {
        this.groupId = this.$route.query.group
      }
    },
    mounted () {
      this.initGroup()
    },
    methods: {
      async onSubmit () {
        if (this.disabled || this.loading) return
        this.loading = true
        // 检测是否存在上传失败的图片
        if (this.composePhoto.some(item => Object.keys(item).length === 0)) {
          this.$Message.error(this.$t('release.has_failed_upload'))
          this.loading = false
          return
        }
        const images = []
        if (this.composePhoto.length) {
          this.composePhoto.map(p => {
            this.content += `\n@![图片描述](${p.id})`
            images.push(p.id)
          })
        }
        const params = {
          groupId: this.groupId || this.group.id,
          title: this.title,
          body: this.content,
          summary: this.content.substr(0, 144),
          sync_feed: Number(this.feed),
          images,
        }
        const data = await this.$store.dispatch('group/createPost', params)
        this.loading = false
        if (data) {
          this.isneedLeave = false
          this.$Message.success(data.message)
          this.$router.replace({
            name: 'groupPostDetail',
            params: { groupId: data.post.group_id, postId: data.post.id },
          })
        }
      },
      async initGroup () {
        // 从 store 中读取当前圈子信息
        if (!this.group.id && this.groupId) { this.group = this.$store.state.group.current }

        // 如果没有读到, 去远端拉取
        if (!this.group.id && this.groupId) {
          this.group = await this.$store.dispatch('group/getGroupById', {
            groupId: this.groupId,
          })
        }
      },

      selectGroup () {
        this.$refs.chooseGroup.show()
      },
      onGroupChange (group) {
        this.group = group
      },
      fetchDraft () {
        this.loading = true
        api.fetchDraft(this.draftId)
          .then(({ data: {data} }) => {
            this.loading = false
            this.title = data.title
            this.content = data.content
            if (data.group) {
              this.group = data.group
            }
          })
      },
      cancel () {
        const quit = () => {
          this.goBack()
          this.$destroy()
        }
        const saveDraft = () => {
          const images = []
          if (this.composePhoto.length) {
            console.log(this.composePhoto)
            this.composePhoto.map(p => {
              this.content += `\n@![图片描述](${p.id})`
              images.push(p.id)
            })
          }
          const data = {
            groupId: this.groupId || this.group.id,
            group: this.group,
            title: this.title,
            content: this.content,
            images,
          }

          const params = {
            data,
            title: this.title
          }
          api.postDraft(this.draftId, params)
            .then(() => {
              this.$router.go(-1)              
            })
            .finally(() => {
              this.loading = false
            })
        }

        if (!this.title && !this.content) return quit()
        const actions = [
          { style: { color: '#f4504d' }, text: '放弃', method: quit },
          { text: '保存草稿', method: saveDraft },
        ]
        this.$bus.$emit('actionSheet', actions, '取消', '你还有未发布的内容，是否放弃发布？')
      },
    },
    beforeRouteLeave(to, from, next) {
      if (this.isneedLeave) {
        this.isneedLeave = false
        this.cancel()
        this.$nextTick(() => {
          next(false)
        })
      } else {
        if (this.draftId > 0) {
          draftDelete('questions', this.draftId)
        }
        next()
      }
    },
  }
</script>

<style lang="less" scoped>
.p-group-post-create {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;

  .disabled {
    color: #ccc;
  }

  .form-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid @border-color; /* no */

    .input-wrap {
      svg {
        vertical-align: middle;
      }
    }

    .placeholder {
      margin-right: 20px;
      color: #999;
    }
  }

  > main {
    padding: 30px;

    .title-wrap {
      padding-bottom: 15px;
      border-bottom: 2px solid @border-color;
    }

    .content-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 15px;

      .textarea-shadow {
        opacity: 0;
        min-height: 100px;
        padding: 28px 0;
        word-wrap: break-word;
        word-break: break-all;
      }

      textarea {
        position: absolute;
        top: 0;
        display: block;
        font-size: 30px;
        // padding: 28px 0;
        width: 100%;
        height: 100%;
        overflow: scroll;
        resize: none;
        outline: none;
        min-height: 100px;
        background-color: transparent;
        word-wrap: break-word;
        word-break: break-all;
      }
    }

    input,
    textarea {
      width: 100%;
      height: 100%;
      font-size: 30px;
    }
  }

  .form-switch-item {
    position: fixed;
    bottom: 0;
    border-top: 1px solid @border-color; /* no */
  }
}
</style>
